import { imageLoader } from "next-image-loader/image-loader";

imageLoader.loader = ({ src, width, quality = 75 }) => {
  if (!src.match("image.luminews.my")) {
    return `${src}/?w=${width}&q=${quality}`;
  }

  const relativeSrc = (src) => src.split("/").pop();

  return `${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/${relativeSrc(
    src
  )}?w=${width}&q=${quality}`;
};
